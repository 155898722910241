<template>
    <div class="GlobalFormDemo">
      <el-form :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
        <el-form-item label="操作时间" prop="channel">
          <el-date-picker
            v-model="formData.timeRange"
            format="yyyy-MM-dd"
            value-format="timestamp"
            type="daterange"
            range-separator="~"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商户" prop="companyId">
          <el-select v-model="formData.companyId" filterable clearable placeholder="请输入商户查询" @change="companyIdChange">
            <el-option v-for="item in businessList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="操作人" prop="operatorAccount">
          <el-select :disabled="!formData.companyId" v-model="formData.operatorAccount" filterable clearable placeholder="请输入名称查询">
            <el-option v-for="item in staffList" :key="item.operatorAccount" :label="item.operatorName" :value="item.operatorAccount" />
          </el-select>
        </el-form-item>
        <el-form-item label="操作模块" prop="nodeName">
          <el-select v-model="formData.nodeName" placeholder="请选择" filterable clearable>
            <el-option v-for="item in nodeList" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-button size="small" type="primary" round @click="handleConfirm">查询</el-button>
      </el-form>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import _api from "@/utils/request";
  export default {
    name: 'Form',
    data() {
      return {
        formData: {
          operatorAccount: '',
          companyId: '',
          nodeName: '',
          timeRange: [
            moment().day(moment().day()).startOf('day').valueOf(),
            moment().endOf('day').valueOf()
          ]
        },
        // pickerOptions: {
        //   onPick: ({ maxDate, minDate }) => {
        //     if (minDate && this.pickerMinDate) {
        //       this.pickerMinDate = null;
        //     } else if (minDate) {
        //       this.pickerMinDate = minDate.getTime();
        //     }
        //   },
        //   disabledDate: (time) => {
        //     if (this.pickerMinDate) {
        //       return (
        //         time.getTime() > this.pickerMinDate + this.day31 ||
        //         time.getTime() < this.pickerMinDate - this.day31
        //       );
        //     }
        //     return false;
        //   },
        // },
        nodeList: [],
        staffList: [],
        businessList: []
      }
    },
    created(){
      this.getNodeList()
      this.operatorBusinessList()
      this.handleConfirm()
    },
    methods: {
      companyIdChange(val) {
        this.staffList = []
        this.formData.operatorAccount = ''
        if(val){
          this.operatorList()
        }
      },
      getNodeList() {
        _api.getStorePCNodeNameList().then(res => {
          this.nodeList = res.data
        })
      },
      operatorBusinessList() {
        _api.operatorBusinessList({reportChannel:'STORE_PC'}).then(res => {
          this.businessList = res.data
        })
      },
      operatorList() {
        _api.operatorList({ reportChannel: 'STORE_PC',queryBusinessId: this.formData.companyId}).then(res => {
          this.staffList = res.data
        })
      },
      //查询
      handleConfirm() {
        console.log(this.formData);
        const [startTime, endTime] = this.formData.timeRange || []
        const SeachParams = {
          ...this.formData,
          startTime, endTime,
          pageNum: 1,
          pageSize: 50,
        } 
        this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
      }
    }
  }
  </script>
  