<template>
  <div class="StaffManage-list">
    <!-- <GlobalInfoBar
        title="职员列表"
        content="主要管理平台职员账号的新增及编辑"
    /> -->
    <div class="log-type-radio">
      <el-radio-group v-model="logType">
        <el-radio-button label="01">平台管理后台</el-radio-button>
        <el-radio-button label="02">门店管理后台</el-radio-button>
        <el-radio-button label="03">导表日志</el-radio-button>
      </el-radio-group>
    </div>
    <GlobalChunk
        v-if="logType == '01'"
        icon="search"
        title="筛选查询"
    >
      <div slot="filter">
        <PlatformFrom />
      </div>
      <PlatformTable />
    </GlobalChunk>
    <GlobalChunk
        v-if="logType == '02'"
        icon="search"
        title="筛选查询"
    >
      <div slot="filter">
        <StoreForm />
      </div>
      <StoreTable />
    </GlobalChunk>
    <GlobalChunk
        v-if="logType == '03'"
        icon="search"
        title="筛选查询"
    >
      <div slot="filter">
        <ExportForm />
      </div>
      <ExportTable />
    </GlobalChunk>
  </div>
</template>

<script>
import PlatformFrom from './From'
import PlatformTable from './Table'
import StoreForm from './formTable/StoreForm.vue'
import StoreTable from './formTable/StoreTable.vue'
import ExportForm from './formTable/ExportForm.vue'
import ExportTable from './formTable/ExportTable.vue'
export default {
  name: 'StaffManage-list',
  components: {PlatformFrom,PlatformTable,StoreForm,StoreTable,ExportForm,ExportTable},
  data(){
    return{
      logType: "01",
    }
  },
  methods:{
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.StaffManage-list{
  .log-type-radio{
    margin-bottom: 20px;
  }
}
</style>
